.multipleButtonDiv {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.multipleAddDiv {
	background-color: rgba(223, 218, 218, 0.171);
	padding: 15px;
}
.totalNumber {
	font-weight: 400;
}
.textAlignLeft {
	text-align: left;
}
.checkboxDivHeader {
	text-align: center;
}
.itemsNumberDiv {
	font-size: 12px;
	color: gray;
	font-weight: 400;

	> span {
		color: black;
		font-weight: 500;
	}
}
.rowConfigDriv {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.rowDropDownDiv {
	display: flex;
	.rowLabel {
		align-self: center;
		font-weight: 400;
		margin-right: 5px;
	}
}
.groupErrorDiv {
	color: red;
	font-weight: 400;
	font-size: 12px;
}
.detailIconDiv {
	background-color: black;
	width: 40px;
	height: 40px;
	color: white;
	text-align: center;
	position: absolute;
	top: -25px;
	left: -25px;
	align-items: center;
	transform: rotate(-45deg);
	padding-top: 25px;

	> span {
		transform: rotate(45deg);
		display: inline-block;
		font-weight: 600;
		font-size: 10px;
	}
	&:hover {
		outline: none;
		cursor: pointer;
	}
	&:focus {
		outline: none;
	}
}
.upperButtonsContainerDiv {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.toggleDiv {
	text-align: center;
	display: flex;
	margin: 10px;
	flex-direction: row;
	justify-content: flex-start;
	> button {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		outline: none;
		border: none;
		background-color: rgb(226, 226, 226);
		font-weight: 400;
		font-size: 15px;
		padding: 5px 10px;
		border-radius: 15px;
		color: rgb(80, 79, 79);
		&:hover {
			cursor: pointer;
			color: black;
		}
		&:focus {
			outline: none;
			border: none;
		}
		.buttonSentence {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}
}
.downloadButton {
	color: green !important;
	border: 1px solid green;
	padding: 10px 15px;
	background-color: inherit;
	&:hover {
		cursor: pointer;
	}
}
.excelLoadingDiv {
	width: 100px;
}
.exportButton {
	color: rgb(95, 93, 93);
	border: 1px solid gray;
	padding: 10px 15px;
	background-color: inherit;
	&:hover {
		cursor: pointer;
	}
}
.dateErrorDiv {
	font-size: 11px;
	line-height: 0;
	font-weight: 400;
	color: red;
}
.filterDiv {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	position: -webkit-sticky;
	position: sticky;
	z-index: 100;
	top: 0;
	background-color: white;
	> div {
		display: flex;
	}
}
.titleDivAction {
	margin-bottom: 80px;
	text-align: right;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	justify-content: center;
	> span {
		margin-left: 5px;
	}
	svg {
		font-size: 12px;
	}
}
.linkButtonDiv {
	text-align: center;
}
.inlineButton {
	font-size: 13px;
	padding: 2px;
	border: 1px solid orange;
	border-radius: 5px;
	background-color: white;
	color: orange;
	outline: none;
	width: fit-content;
	margin: 5px;
	&:hover {
		outline: none;
		background-color: orange;
		color: white;
		cursor: pointer;
	}
	&:focus {
		outline: none;
	}
	&:disabled {
		cursor: default;
		background-color: white !important;
		border: 1px solid rgba(146, 146, 146, 0.719);
		color: rgba(146, 146, 146, 0.719) !important;
	}
}

.orangeDetails {
	color: orange;
	border: 1px solid orange;
	&:hover {
		background-color: orange;
	}
}

.greenDetails {
	color: rgb(37, 177, 37);
	border: 1px solid rgb(37, 177, 37);
	&:hover {
		background-color: rgb(37, 177, 37);
	}
}

.inlineEditButton {
	color: gray;
	border: 1px solid gray;
	&:hover {
		background-color: gray;
		color: white;
	}
}

.inlineDeleteButton {
	color: red;
	border: 1px solid red;
	&:hover,
	&:focus {
		background-color: red;
		color: white;
	}
}

.inlineTag {
	display: inline-flex;
	font-size: 13px;
	padding: 1px 4px;
	width: fit-content;
	border-radius: 5px;
	margin: 4px;
	color: white;
}

.rideTag,
.paidorderTag {
	background-color: rgb(243, 219, 6);
}
.preparingorderTag {
	background-color: rgb(243, 219, 6);
}
.preparedorderTag {
	background-color: rgb(243, 219, 6);
}
.firsttripTag,
.firstorderTag,
.restaurantTag,
.foodTag,
.warningTag {
	background-color: orange;
}
.deliveryTag,
.timelyTag {
	background-color: rgb(10, 158, 168);
}
.destinationTag,
.paidTag,
.deliveredorderTag {
	background-color: rgb(9, 122, 9);
}
.acceptedTag,
.activeTag,
.groceryTag,
.purchaseTag,
.successTag,
.onlineTag {
	&:focus {
		background-color: rgb(37, 177, 37);
	}
	background-color: rgb(37, 177, 37);
}
.searchingTag,
.fixedTag,
.bTag,
.verifiedTag,
.paytosparkTag,
.shippingorderTag,
.intripTag {
	background-color: rgb(37, 101, 185);
}
.passengercanceledduringtripTag {
	background-color: rgba(155, 155, 155, 0.733);
}
.drivercanceledTag {
	background-color: rgb(177, 177, 177);
}
.passengercanceledTag {
	background-color: rgb(197, 197, 197);
}
.suspendedTag,
.failedTag,
.shopsupportTag,
.rejectedTag,
.rejectedorderTag,
.offlineTag {
	background-color: rgb(212, 28, 59);
}

.paytoshopTag,
.supportTag,
.deliverynotacceptedorderTag {
	background-color: rgb(114, 5, 5);
}
.arrivedTag {
	background-color: rgb(9, 66, 2);
}
.waitingTag,
.percentTag,
.bbbTag,
.messageTag {
	background-color: rgb(137, 10, 168);
}
.comingTag,
.bbTag,
.percentageTag,
.paytodriverTag,
.supportticketTag {
	background-color: rgb(218, 20, 135);
}
.pickedupTag {
	background-color: rgb(110, 165, 8);
}

.pendingTag,
.pendingorderTag,
.waitingTag,
.unpaidTag {
	background-color: rgb(87, 86, 87);
}
.finishedduetonotpayingTag {
	background-color: rgba(87, 86, 87, 0.747);
}
.maleTag,
.finishedduetonotpayingorderTag {
	background-color: rgb(108, 5, 168);
}
.femaleTag {
	background-color: rgb(209, 17, 183);
}

.copyClipboard {
	&:hover {
		cursor: pointer;
		font-weight: 400;
	}
}

.iconCell {
	text-align: center;
}
.sortDiv {
	font-size: 14px;
	font-weight: 400;
	&:hover {
		cursor: pointer;
	}
	display: flex;
	flex-direction: row;
	justify-content: center;
	> span {
		margin-left: 5px;
	}
	svg {
		font-size: 12px;
	}
}
.fitTitleDivCheckbox {
	height: fit-content !important;
	padding-bottom: 0 !important;
}
.fitTitleDiv {
	height: fit-content !important;
	padding-bottom: 0 !important;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	justify-content: center;
	> span {
		margin-left: 5px;
	}
	svg {
		font-size: 12px;
	}
}
.titleDiv {
	font-size: 14px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	justify-content: center;
	> span {
		margin-left: 5px;
	}
	svg {
		font-size: 12px;
	}
}
.titleDivSmall {
	font-size: 14px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	justify-content: center;
	> span {
		margin-left: 5px;
	}
	svg {
		font-size: 12px;
	}
}
.dropdownWrapper {
	text-align: center;
	// margin: 15px 0;
	margin: 0;
}
.bottonButton {
	// margin-top: 41px;
}

@media screen and (min-width: 600px) {
	.upperButtonsContainerDiv {
		flex-direction: row;
	}
	.filterDiv {
		flex-direction: row-reverse;
	}
	.rowConfigDriv {
		flex-direction: row;
	}
	.itemsNumberDiv {
		align-self: flex-end;
	}
}

:global {
	body {
		overflow-x: hidden !important;
		width: 100% !important;
		background-color: white !important;
		color: black !important;
	}
	.rt-td {
		text-align: center;
	}
	.ReactTable {
		.rt-table {
			position: relative;
			height: 470px;
		}
	}
	.ReactTable {
		.rt-thead {
			.rt-th {
				input {
					text-align: center !important;
				}
			}
		}
	}
	.ReactTable {
		.rt-tbody {
			.rt-tr-group {
				position: relative;
				overflow: hidden;
			}
		}
	}
	.ReactTable {
		.rt-thead {
			.rt-th {
				text-align: center !important;
			}
		}
	}
	.ReactTable {
		.actions-right {
			text-align: center !important;
		}
	}
	.ReactTable {
		.rt-thead {
			.rt-resizable-header-content {
				> div {
					padding-bottom: 46px;
					height: 135px;
					// height: 120px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					> .makeStyles-formControl-432,
					.MuiFormControl-fullWidth {
						padding: 0 !important;
						margin: 0 !important;
					}
				}
				> .inputWithoutFrom {
					padding-bottom: 5px;
					height: 100px;
				}
				> .dropDownWithoutFrom {
					padding-bottom: 0;
					height: 100px;
				}
				> .twoFilter {
					padding-bottom: 0;
				}
				> .dropDownFilter {
					padding-bottom: 0;
					justify-content: flex-start;
				}
			}
		}
	}

	.rdt input.form-control {
		font-size: 14px !important;
		padding-bottom: 0 !important;
	}

	.rdt input.form-control::placeholder {
		color: rgb(165, 165, 165) !important;
	}

	.makeStyles-content-3 {
		padding: 0px 15px !important;
	}
	.MuiPaper-elevation8 {
		border: 1px solid gray;
		border-radius: 3px !important;
		box-shadow: none !important;
	}
	.Sidebar-itemLink-27,
	.jss27 {
		margin: 10px 5px 0 !important;
		padding: 10px 5px !important;
	}
	.Sidebar-collapseItemLink-38 {
		margin: 0 5px !important;
	}
	.makeStyles-labelRoot-274 {
		line-height: 1 !important;
	}
	.makeStyles-labelRootError-246 {
		line-height: 1 !important;
	}
	.makeStyles-labelRootError-384 {
		font-size: 15px !important;
	}

	.MuiPaper-elevation1,
	.makeStyles-dropdown-560,
	.MuiPaper-rounded {
		height: fit-content;
		max-height: 300px;
		overflow: auto;
	}
	.rdt input.form-control {
		&:focus {
			background-image: linear-gradient(#636361, #636361), linear-gradient(#d2d2d2, #d2d2d2) !important;
		}
	}
	.rdtDay.rdtActive {
		background-color: #636361;
	}
	.rt-resizable-header-content {
		overflow: visible !important;
	}
	.MuiFormControl-fullWidth {
		margin-bottom: 17px !important;
		padding-top: 25px !important;
	}
	.rdtPicker,
	th.rdtSwitch,
	th.rdtNext,
	th.rdtPrev,
	.dow {
		color: gray !important;
	}
	.-pagination,
	.-pageJump,
	.select-wrap {
		input,
		select {
			background-image: linear-gradient(gray, gray), linear-gradient(#d2d2d2, #d2d2d2) !important;
			background-size: 0 2px, 100% 1px;
			background-color: rgba(0, 0, 0, 0);
		}
	}
	.MuiDialog-paperScrollPaper {
		max-height: 90vh !important;
	}
	.MuiDialogContent-root {
		padding: 0 !important;
	}
	.makeStyles-mainPanel-2,
	.makeStyles-mainPanelWithPerfectScrollbar-7,
	.ps,
	.ps--active-y {
		overflow: auto !important ;
	}
	.makeStyles-mainPanelWithPerfectScrollbar-7 {
		overflow: auto !important ;
	}
	.MuiFormLable-root,
	.Mui-focused {
		font-size: 18px !important;
	}
	.makeStyles-button-193 {
		overflow: hidden !important;
	}
	.Orders_downloadButton__8tyud {
		color: green !important;
		border: 1px solid green;
		padding: 10px 15px;
		background-color: inherit;
	}
	.rdtToday {
		border: 1px solid purple !important;
	}
}
